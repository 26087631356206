<template>
  <v-row no-gutters class="flex-column">
    <v-card outlined>
      <v-card-text>
        <v-data-table :headers="table.headers" :items="allChallenges" :loading="allChallenges.length == 0">
          <template v-slot:item.image="{ item }">
            <v-btn icon @click="showImage(item.id)">
              <v-icon>mdi-image</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="editData(item)">
              <v-icon>mdi-file-edit</v-icon>
            </v-btn>
            <v-btn icon @click="deleteConfirmation(item)">
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="edit.dialog" width="800" persistent>
      <v-card dark>
        <v-toolbar tile flat>
          <v-btn
            depressed
            color="accent"
            @click="saveData"
            :loading="edit.loading"
            :disabled="edit.loading"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="edit" v-if="edit.data">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Event"
                  :items="allEvents"
                  item-text="title"
                  item-value="id"
                  v-model="edit.data.event_id"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Nama"
                  v-model="edit.data.title"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              label="Pertanyaan"
              v-model="edit.data.description"
            ></v-textarea>
            <v-textarea label="Jawaban" v-model="edit.data.answer"></v-textarea>
            <v-file-input label="Gambar" v-model="edit.image"></v-file-input>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="700">
      <v-alert prominent dark icon="mdi-alert" color="warning" class="mb-0">
        <v-row no-gutters>
          Apakah anda yakin ingin menghapus data ini?
          <v-spacer></v-spacer>
          <v-btn large text @click="handleDelete">
            <span>ya</span>
          </v-btn>
          <v-btn large depressed @click="del.dialog = false">
            <span>batal</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-dialog v-model="image.show" width="800">
      <v-card>
        <v-skeleton-loader
          type="image"
          v-if="image.src == null"
        ></v-skeleton-loader>
        <v-img v-if="image.src" :src="image.src" contain>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="image.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-img>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.vm" :color="snackbar.color" multi-line>
      <v-icon left>{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
      <template v-slot:action>
        <v-btn @click="snackbar.vm = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      table: {
        headers: [
          { text: "Event", value: "events.title" },
          { text: "Nama", value: "title" },
          { text: "Pertanyaan", value: "description" },
          { text: "Jawaban", value: "answer" },
          { text: "Gambar", value: "image" },
          { text: "Aksi", value: "action", sortable: false },
        ],
      },
      del: {
        id: null,
        dialog: false,
        loading: false,
      },
      edit: {
        data: null,
        dialog: false,
        loading: false,
        image: null,
      },
      snackbar: {
        vm: false,
        text: null,
        color: null,
        icon: null,
      },
      image: {
        show: false,
        src: null,
      },
    };
  },
  methods: {
    ...mapActions([
      "fetchEvents",
      "fetchChallenges",
      "updateChallenge",
      "deleteChallenge",
    ]),
    editData(values) {
      this.edit.data = values;
      this.edit.dialog = true;
    },
    saveData() {
      this.edit.loading = false;

      if (this.edit.image) {
        let formData = new FormData();

        formData.append("event_id", this.edit.data.event_id);
        formData.append("title", this.edit.data.title);
        formData.append("description", this.edit.data.description);
        formData.append("answer", this.edit.data.answer);
        formData.append("image", this.edit.image);

        if (this.updateChallenge(formData)) {
          this.showNotification("success");
          this.edit.loading = false;
        } else {
          this.showNotification("error");
          this.edit.loading = false;
        }
      } else {
        let data = this.edit.data;
        delete data.image;

        if (this.updateChallenge(data)) {
          this.showNotification("success");
          this.edit.loading = false;
        } else {
          this.showNotification("error");
          this.edit.loading = false;
        }
      }
    },
    deleteConfirmation(values) {
      this.del.id = values.id;
      this.del.dialog = true;
    },
    handleDelete() {
      this.del.loading = true;
      let id = this.del.id;

      if (this.deleteChallenge(id)) {
        this.showNotification("success");
        this.del.loading = false;
        this.del.dialog = false;
      } else {
        this.showNotification("error");
        this.del.loading = false;
      }
    },
    async showImage(id) {
      await axios
        .get(`${process.env.VUE_APP_KB_API_URL}/api/challenges/image/${id}`)
        .then((res) => {
          this.image.src = res.data;
          this.image.show = true;
        })
        .catch((err) => {
          console.log(err);
          this.showNotification("error");
        });
    },
    showNotification(status) {
      this.snackbar.vm = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.text = "Data berhasil disimpan";
          break;
        case "failed":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          this.snackbar.text = "Data gagal disimpan";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allEvents", "allChallenges"]),
  },
  mounted() {
    this.fetchEvents();
    this.fetchChallenges();
  },
};
</script>